<template>
  <div>
    <div>
      <h3 class="card-label">
        {{ $t('user_send_message') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-md-12">
      <div class="card card-custom">
        <div class="card-body row">
          <div class="col-md-6">
            <label for="users">
              {{ $t('all_users') }}
            </label>

            <multiselect v-model="users" id="users"
                         :placeholder="$t('all_users')"
                         label="name"
                         track-by="id"
                         :options="users_list"
                         :multiple="true"
                         :taggable="false"
                         :show-labels="false"
                         :show-no-options="false"
                         :show-no-results="false">
            </multiselect>
          </div>
          <div class="col-md-6">
            <label>{{ $t('notes') }}</label>
            <textarea v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''" :placeholder="$t('notes')" style="height: 115px;">
                            </textarea>
            <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
          </div>
        </div>
      </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="send">
              {{ $t('send') }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-send-message",
  data() {
    return {
      mainRoute: 'user/send_message',
      mainRouteDependency: 'base/dependency',
      data: {
        users: [],
        notes: "",
      },
      users: [],
      users_list: [],
      isEditing: false,
      validation: null,

    };
  },
  watch: {
    users: function (val) {
      this.data.users = [];
      if (val) {
        this.data.users = val.map(row => row.id);
      }
    },
  },
  methods: {
    send() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });

    },


    getUsers() {
      ApiService.get(this.mainRouteDependency + "/users").then((response) => {
        this.users_list = response.data.data;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.send_message_users")}]);
    this.getUsers();
  },
};
</script>
